import React, { useState, useEffect, HTMLInputTypeAttribute } from "react";
import LockedComponent from "../components/LockedComponent";
import { graphql, useStaticQuery, navigate } from "gatsby";
import "../style.scss";

type DataProps = {
  allContentfulNda: {
    edges: [
      {
        node: {
          video: {
            url: string;
          };
          password: string;
        };
      }
    ];
  };
};

const SecretPage = () => {
  const query: DataProps = useStaticQuery(graphql`
    query {
      allContentfulNda {
        edges {
          node {
            video {
              url
            }
            password
          }
        }
      }
    }
  `);
  const node = query.allContentfulNda.edges[0].node;
  const [locked, setLocked] = useState(true);
  const [input, setInput] = useState("");
  const [message, setMessage] = useState("Enter password:");

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setInput(e.currentTarget.value);
  };

  // const requestPass = () => {
  //   let input = prompt("Enter password:");
  //   while (input !== node.password) {
  //     if (!input) {
  //       navigate("/");
  //       break;
  //     }
  //     input = prompt("Wrong, try again:");
  //   }
  //   if (input === node.password) setLocked(false);
  // };

  // useEffect(() => {
  //   requestPass();
  // }, []);

  const validatePass = (input: string) => {
    if (input === "") return;
    if (input === node.password) {
      setLocked(false);
    } else {
      setMessage("Wrong, try again:");
    }
  };

  return (
    <main className="portfolio-page">
      <section className="portfolio-section">
        {locked && (
          <div className="secret-container">
            <span>{message}</span>
            <input
              className="secret-input"
              value={input}
              onChange={handleChange}
              type="password"
              autoComplete="new-password"
            />
            <button
              className="secret-button"
              onClick={() => {
                validatePass(input);
              }}
            >
              OK
            </button>
          </div>
        )}
        {!locked && (
          <LockedComponent videoUrl={node.video.url} locked={locked} />
        )}
      </section>
    </main>
  );
};

export default SecretPage;
