import React from "react";

interface LockedComponentProps {
  videoUrl: string;
  locked: boolean;
}

const LockedComponent = ({ videoUrl, locked }: LockedComponentProps) => {
  return (
    <div className="locked-wrapper">
      {!locked && (
        <video className="locked-video" width="100%" controls muted={false}>
          <source src={videoUrl} />
        </video>
      )}
    </div>
  );
};

export default LockedComponent;
